<template>
  <vx-card>
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <vs-row vs-w="12">
        <product-detail @deleteAllGroup="deleteGroup" :key="prodkey" :itemId="itemId"></product-detail>
      </vs-row>

      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <stock-list
            @changegroup="chgp"
            @setNewkey="setKey"
            :itemId="itemId"
            :key="inGroupKey"
            incase="inGroup"
          ></stock-list>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <stock-list
            @changegroup="chgp"
            @setNewkey="setKey"
            :itemId="itemId"
            :key="allKey"
            incase="all"
          ></stock-list>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>
<script>
import StockList from "./StockList.vue";
import ProductDetail from "./Components/ProductDetail.vue";
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  props: {
    // list: Array,
    case: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      welcome: "This is your profile",
      allKey: "",
      inGroupKey: "",
      itemId: "",
      prodkey: "",
      aProduct: {},
      categories: []
    };
  },
  async mounted() {
    this.allKey = this.generateRandom(5);
    this.inGroupKey = this.generateRandom(5);
    this.prodkey = this.generateRandom(5);
  },
  methods: {
    deleteGroup(newid) {
      this.itemId = newid;
      this.prodkey = this.generateRandom(5);
      this.inGroupKey = this.generateRandom(5);
      this.inGroupKey = this.generateRandom(5);
    },

    async chgp(newid) {
      this.prodkey = this.generateRandom(5);
      this.inGroupKey = this.generateRandom(5);
      this.itemId = newid;
      // console.log("[WEB GROUP] AFTER  CHANGE PROD >>>> ", this.itemId);
    },
    setKey() {
      this.inGroupKey = this.generateRandom(5);
      // console.log("this.inGroupKey >>>> ", this.inGroupKey);

      this.allKey = this.generateRandom(5);
      // console.log("this.allKey >>>> ", this.allKey);

      this.prodkey = this.generateRandom(5);
    }
    // welcomeMessage() {
    //   console.log("Hello Method");
    // }
  },
  created() {
    this.itemId = this.$route.params.productid;
  },
  components: {
    StockList,
    ProductDetail
  }
};
</script>

<style >
</style>
