<template>
  <vx-card>
    <div class="grid-layout-container alignment-block" style="width:100%;">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
          <img
            class="responsive rounded"
            :src="getFullpath(aProduct.mainImage)"
            alt="user-upload"
            style="width:120px;"
          />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="flex-start"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <div class="grid-layout-container alignment-block" style="width:100%;">
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <span
                  @click="editItem(aProduct.productItemId)"
                  style="color: #2372CE; font-weight:bold; font-size:18px; cursor:pointer;"
                >{{aProduct.name}}</span>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <b>รหัส : {{aProduct.code}}</b>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <b>ประเภท : {{getCategoryName(aProduct.category)}}</b>
              </vs-col>
            </vs-row>
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <b>สี : {{aProduct.color}}</b>
              </vs-col>
            </vs-row>
            <vs-row v-if="aProduct.group === 'm'" vs-w="12">
              <div class="sm-btn-yellow">m</div>
            </vs-row>
            <vs-row vs-w="12">
              <div
                @click="setToMain(aProduct.productItemId)"
                class="sm-btn-yellow"
                style="margin-right: 10px;"
              >เพิ่มเป็นกลุ่ม</div>
              <div @click="backtostock()" class="sm-btn-black">กลับไปหน้าสต๊อค</div>
            </vs-row>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="12"
              vs-offset="6"
            >
              <div @click="destroyGroup(aProduct.productItemId)" class="sm-btn-red">ล้างข้อมูลกลุ่ม</div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>
<script>
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  props: {
    itemId: String
    // item: Object,
    // catelist: Array
  },
  mixins: [shapeFormat],
  data() {
    return {
      aProduct: {},
      categories: []
    };
  },
  async mounted() {
    this.aProduct = await this.getOne("/api/productitem/" + this.itemId);
    this.categories = await this.getList("/api/category");
    // console.log("NEW IN PRODUCT DETAIL >>> ", this.aProduct.productItemId);
  },
  methods: {
    async setToMain(productId) {
      var res = await this.$http.post(
        this.$store.state.apiURL + "/api/Nbs4u0m9pehXse/settomain",
        {
          productItemId: productId,
          updateBy: this.currentUser
        }
      );

      var data = await res;
      if (data != null) {
        this.itemid = productId;

        this.$emit("deleteAllGroup", productId);
      }
    },
    backtostock() {
      window.open("/backoffice/stock", "_blank");
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    editItem(productItemId) {
      window.open("/backoffice/create-item/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },

    async destroyGroup(productid) {
      var res = await this.$http.put(
        this.$store.state.apiURL +
          "/api/Nbs4u0m9pehXse/destroygroup/" +
          productid
      );

      if (res != null) {
        this.$emit("deleteAllGroup", productid);
      }
    }
  },
  components: {}
};
</script>

<style >
</style>
